import { TSessionResponse } from './session';
import { TNetworkErrorInfo } from './Api';
import { TInvalidField } from './errors';

export class PaymentPageNetworkError extends Error {
  private readonly _errorDisplayType: 'fullPage' | 'inForm' | 'failedScreen';
  private readonly _errorInvalidFields: TInvalidField[] | undefined;
  private readonly _errorMessage: string | undefined;
  private readonly _errorName: string | undefined;
  private readonly _errorPayload: TSessionResponse | undefined;

  constructor(error: TNetworkErrorInfo) {
    super(error.errorMessage);

    this._errorDisplayType = error.errorDisplayType;
    this._errorMessage = error.errorMessage;
    this._errorInvalidFields = error.errorInvalidFields;
    this._errorMessage = error.errorMessage;
    this._errorName = error.errorName;
    this._errorPayload = error.errorPayload;
  }

  get errorDisplayType() {
    return this._errorDisplayType;
  }

  get errorInvalidFields() {
    return this._errorInvalidFields;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  get errorName() {
    return this._errorName;
  }

  get errorPayload() {
    return this._errorPayload;
  }
}
