import { TextStyles, theme as paylerTheme } from '@payler/ui-theme';
import { ComponentStyleConfig } from '@chakra-ui/react';

const borderedFloating = {
  field: {
    pb: 1,
    pt: 3,
    border: '1px solid',
    borderColor: `primary.100`,
    color: 'primary.500',
    boxShadow: 'input',
    bg: 'secondary.500',
    _placeholder: {
      color: 'transparent',
    },
    _hover: {
      '& + label': {
        color: 'primary.400',
      },
      boxShadow: 'inputHover',
    },
    '&:not(:placeholder-shown) + label': {
      textStyle: TextStyles.Caption12Regular,
      top: 1,
      fontSize: '12px',
    },
    _focus: {
      color: 'primary.500',
      boxShadow: 'inputFocus',
      bg: 'brands.100',
      border: '1.5px solid',
      borderColor: 'brands.500',
      _invalid: {
        bg: 'red.100',
        border: '1.5px solid',
        borderColor: 'red.500',
      },
      _placeholder: {
        color: 'primary.200',
      },
    },
    _invalid: {
      color: 'primary.500',
      boxShadow: `inputError`,
      bg: 'red.100',
      border: '1.5px solid',
      borderColor: 'red.500',
      _focus: {
        borderColor: 'red.500',
        boxShadow: `inputError`,
      },
    },
    _disabled: {
      opacity: 1,
      bg: 'primary.25',
      color: 'primary.350',
      '& + label': {
        color: 'primary.300',
      },
    },
    _readOnly: {
      opacity: 1,
      bg: 'primary.25',
      color: 'primary.350',
      '& + label': {
        color: 'primary.300',
      },
      _focus: {
        borderColor: `primary.100`,
      },
    },
    '&:-webkit-autofill ~ label': {
      textStyle: TextStyles.Caption12Regular,
      top: 1,
      fontSize: '12px',
    },
  },
};

const borderedFloatingWithBg = {
  ...borderedFloating,
  field: { ...borderedFloating.field, bg: 'primary.25' },
};

export const InputStyles: ComponentStyleConfig = {
  ...paylerTheme.components.Input,
  baseStyle: {
    ...paylerTheme.components.Input.baseStyle,
    field: {
      ...paylerTheme.components.Input.baseStyle.field,
      transitionProperty: 'bg',
      transitionDuration: 'normal',
      textStyle: TextStyles.BodyUI16Regular,
      _placeholder: {
        color: 'primary.350',
      },
      color: 'primary.500',
      _invalid: {
        '&+.chakra-input__right-element': {
          color: 'red.300',
        },
      },
    },
    element: {
      width: 'auto',
      px: 2,
    },
  },
  variants: {
    ...paylerTheme.components.Input.variants,
    borderedFloating,
    borderedFloatingWithBg,
  },
};
