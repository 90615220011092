// FIXME Временное решение, в дальшнейшем данные будут приходить с бэка
export const CURRENCIES: Array<{
  currency: string;
  decimals: number;
}> = [
  {
    currency: 'RUB',
    decimals: 2,
  },
  {
    currency: 'EUR',
    decimals: 2,
  },
  {
    currency: 'USD',
    decimals: 2,
  },
  {
    currency: 'CAD',
    decimals: 2,
  },
  {
    currency: 'BRL',
    decimals: 2,
  },
  {
    currency: 'AED',
    decimals: 2,
  },
  {
    currency: 'INR',
    decimals: 2,
  },
  {
    currency: 'AZN',
    decimals: 2,
  },
  {
    currency: 'GBP',
    decimals: 2,
  },
  {
    currency: 'ASN',
    decimals: 2,
  },
  {
    currency: 'CNY',
    decimals: 2,
  },
  {
    currency: 'JPY',
    decimals: 0,
  },
  {
    currency: 'KRW',
    decimals: 0,
  },
  {
    currency: 'BIF',
    decimals: 0,
  },
  {
    currency: 'CLP',
    decimals: 0,
  },
  {
    currency: 'KMF',
    decimals: 0,
  },
  {
    currency: 'DJF',
    decimals: 0,
  },
  {
    currency: 'GNF',
    decimals: 0,
  },
  {
    currency: 'ISK',
    decimals: 0,
  },
  {
    currency: 'VUV',
    decimals: 0,
  },
  {
    currency: 'PYG',
    decimals: 0,
  },
  {
    currency: 'VND',
    decimals: 0,
  },
  {
    currency: 'UGX',
    decimals: 0,
  },
  {
    currency: 'UYI',
    decimals: 0,
  },
  {
    currency: 'XAF',
    decimals: 0,
  },
  {
    currency: 'XOF',
    decimals: 0,
  },
  {
    currency: 'XPF',
    decimals: 0,
  },
  {
    currency: 'RWF',
    decimals: 0,
  },
  {
    currency: 'BHD',
    decimals: 3,
  },
  {
    currency: 'IQD',
    decimals: 3,
  },
  {
    currency: 'JOD',
    decimals: 3,
  },
  {
    currency: 'KWD',
    decimals: 3,
  },
  {
    currency: 'LYD',
    decimals: 3,
  },
  {
    currency: 'OMR',
    decimals: 3,
  },
  {
    currency: 'TND',
    decimals: 3,
  },
];
