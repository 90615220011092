import React, { PropsWithChildren, PureComponent } from 'react';
import createLogger from 'debug';
import { PaymentPageNetworkError } from '@payler/payment-page-api-gate';
import { FullPageLoader } from '@payler/payment-page-ui-shared';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FullPageErrorScreen } from '../lazy-components';

interface IErrorBoundaryProps extends PropsWithChildren, WithTranslation {}

type TErrorBoundaryState = {
  hasError: boolean;
  error: PaymentPageNetworkError | Error | null;
};

const log = createLogger('ErrorBoundary');

export class ErrorBoundaryComponent extends PureComponent<
  IErrorBoundaryProps,
  TErrorBoundaryState
> {
  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    log('Error handled %o', error);
  }

  override render() {
    if (this.state.hasError) {
      const { error } = this.state;
      const isPaymentPageError = error instanceof PaymentPageNetworkError;
      const isPreparedNetworkError =
        isPaymentPageError && error?.errorDisplayType === 'fullPage';

      const oopsText = this.props?.i18n?.t('errorPage:oops') || '';

      return (
        <React.Suspense fallback={<FullPageLoader />}>
          <FullPageErrorScreen
            title={isPreparedNetworkError ? undefined : oopsText}
            errorName={isPaymentPageError ? error?.errorName : undefined}
            errorMessage={isPaymentPageError ? error?.errorMessage : undefined}
          />
        </React.Suspense>
      );
    }
    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);
