import { DifferentColors } from '../types';

export const darkCPColors: DifferentColors = {
  primary: {
    500: '#ffffff',
    400: 'rgba(255, 255, 255, 0.68)',
    350: 'rgba(255, 255, 255, 0.54)',
    300: 'rgba(255, 255, 255, 0.32)',
    200: 'rgba(255, 255, 255, 0.16)',
    100: 'rgba(255, 255, 255, 0.08)',
    50: 'rgba(255, 255, 255, 0.06)',
    25: 'rgba(255, 255, 255, 0.02)',
  },
  secondary: {
    500: '#121212',
    400: 'rgba(18, 18, 18, 0.68)',
    350: 'rgba(18, 18, 18, 0.54)',
    300: 'rgba(18, 18, 18, 0.32)',
    200: 'rgba(18, 18, 18, 0.16)',
    100: 'rgba(18, 18, 18, 0.08)',
    50: 'rgba(18, 18, 18, 0.06)',
    25: 'rgba(18, 18, 18, 0.02)',
  },
  other: {
    bg: '#080808',
    menuBG: '#1C1C1C',
  },
};
