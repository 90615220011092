import { css, keyframes } from '@emotion/react';
import { FC, useMemo } from 'react';

const animation = keyframes`
  from {
    opacity: 1;
    transform: translateY(25%);
  }
  to {
    opacity: 0.1;
    transform: translateY(-25%);
  }
`;

const getLoaderCss = (color: string) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    transform: translateY(25%);
    width: 12px;
    height: 12px;
    margin: 6px 3px;
    border-radius: 50%;
    background-color: ${color};
    opacity: 1;
    animation: ${animation} 0.6s infinite alternate;
  }
  div:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  div:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;

type TBouncingDotsLoaderProps = {
  color?: string;
};

export const BouncingDotsLoader: FC<TBouncingDotsLoaderProps> = ({
  color = '#7B61FF',
}) => {
  const loaderCss = useMemo(() => getLoaderCss(color), [color]);

  return (
    <div css={loaderCss}>
      <div />
      <div />
      <div />
    </div>
  );
};
