import { useMemo } from 'react';
import { theme } from '@payler/payment-page-ui-shared';
import { useFormVariantContext } from '../context/form-variant-context';

const paysterThemeLazy = import('../theme/payster').then((module) => ({
  default: module.paysterTheme,
}));

/**
 * Возвращает тему в зависимости от варианта формы
 */
export const useTheme = () => {
  const { formVariant } = useFormVariantContext();

  return useMemo(async () => {
    switch (formVariant) {
      case 'payster':
        return paysterThemeLazy.then((th) => th.default);
      default:
        return Promise.resolve(theme);
    }
  }, [formVariant]);
};
