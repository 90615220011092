import { Box, CardHeader, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { FC, useMemo } from 'react';
import { useLayout } from '../../context';
import { SpaceProps } from '@chakra-ui/styled-system';
import { MayBe } from '@payler/utils';

type TErrorHeaderProps = { errorText?: MayBe<string> };
type TScreenHeaderProps = TErrorHeaderProps & SpaceProps;

export const ScreenHeader: FCC<TScreenHeaderProps> = ({
  children,
  errorText,
  ...rest
}) => {
  const { isDesktop } = useLayout();

  const headerBoxProps = useMemo(
    () =>
      isDesktop
        ? {
            w: 'full',
            py: 3,
            px: 4,
          }
        : {
            w: 'full',
            p: 2,
          },
    [isDesktop]
  );

  return (
    <CardHeader p={0}>
      <VStack spacing={0}>
        <ErrorHeader errorText={errorText} />
        <Box {...headerBoxProps} {...rest}>
          {children}
        </Box>
      </VStack>
    </CardHeader>
  );
};

const ErrorHeader: FC<TErrorHeaderProps> = ({ errorText }) => {
  const { isDesktop } = useLayout();

  if (!errorText) {
    return null;
  }

  return (
    <Box w="full" p={2} bg="red.500" textAlign="center">
      <Text
        textStyle={
          isDesktop ? TextStyles.BodyText16Medium : TextStyles.Subtitle14Medium
        }
        color="secondary.500"
      >
        {errorText}
      </Text>
    </Box>
  );
};
