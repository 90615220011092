import {
  HStack,
  PinInput,
  PinInputField,
  UsePinInputProps,
} from '@chakra-ui/react';
import React, { memo } from 'react';

export interface OtpProps
  extends Omit<UsePinInputProps, 'otp' | 'placeholder'> {
  /** Количество ячеек (инпутов) */
  length: number;
}

export const Otp = memo<OtpProps>((props) => {
  const { length, ...rest } = props;
  const pinInputFields = [];
  for (let i = 0; i < length; i++) {
    pinInputFields.push(
      <PinInputField
        key={i}
        border="none"
        borderRadius="12px"
        autoComplete="off"
      />
    );
  }
  return (
    <HStack w="100%" justifyContent="space-between">
      <PinInput {...rest} placeholder="-">
        {pinInputFields}
      </PinInput>
    </HStack>
  );
});
Otp.displayName = 'Otp';
export default Otp;
