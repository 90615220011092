import { CardBody, CardBodyProps } from '@chakra-ui/react';
import { useLayout } from '../../context/layout-context';

export const ScreenBody: FCC<CardBodyProps> = ({ children, ...rest }) => {
  const { isDesktop } = useLayout();
  return (
    <CardBody py={0} px={isDesktop ? 4 : 2} {...rest}>
      {children}
    </CardBody>
  );
};
