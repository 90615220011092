import { GeneralColors } from '../types';

export const generalColors: GeneralColors = {
  brands: {
    500: '#7B61FF',
    300: 'rgba(123, 97, 255, 0.32)',
    100: 'rgba(123, 97, 255, 0.1)',
    hover:
      'linear-gradient(0deg, rgba(8, 8, 8, 0.1), rgba(8, 8, 8, 0.1)), #7B61FF',
  },
  red: {
    500: '#F44336',
    300: 'rgba(244, 67, 54, 0.32)',
    100: 'rgba(244, 67, 54, 0.1)',
  },
  yellow: {
    500: '#F5A623',
    300: 'rgba(245, 166, 35, 0.32)',
    100: 'rgba(245, 166, 35, 0.1)',
  },
  green: {
    500: '#00AD68',
    300: 'rgba(0, 173, 104, 0.32)',
    100: 'rgba(0, 173, 104, 0.1)',
  },
  violet: {
    500: '#7B61FF',
    300: 'rgba(123, 97, 255, 0.32)',
    100: 'rgba(123, 97, 255, 0.1)',
  },
  white: {
    500: '#FFFFFF',
    300: 'rgba(255, 255, 255, 0.32)',
    100: 'rgba(255, 255, 255, 0.1)',
  },
  black: {
    500: '#000000',
    400: 'rgba(8, 8, 8, 0.68)',
    300: 'rgba(0, 0, 0, 0.32)',
    100: 'rgba(0, 0, 0, 0.1)',
  },
  orange: {
    500: '#FF650F',
    300: 'rgba(255, 101, 15, 0.32)',
    100: 'rgba(255, 101, 15, 0.1)',
  },
  pink: {
    500: 'rgba(231, 66, 139, 1)',
    300: 'rgba(231, 66, 139, 0.32)',
    100: 'rgba(231, 66, 139, 0.1)',
  },
  hover:
    'linear-gradient(0deg, rgba(8, 8, 8, 0.1), rgba(8, 8, 8, 0.1)), #7B61FF',
  hoverForBoxShadow: '#4678FF',
};
