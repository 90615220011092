import {
  PAYSTER_FLOW_METHODS,
  TAllMethods,
  TApmMethods,
  TPageUiPayMethods,
  TPaymentDto,
} from '@payler/payment-page-api-gate';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useConfirmPayment, useCreateApmPayment } from '../../../hooks/payment';
import {
  useAvailablePaymentMethods,
  usePaymentPatchSessionState,
  usePaymentMethod,
  useInvalidatePaymentSession,
  usePaymentSessionState,
} from '../../../hooks/payment/session';
import createLogger from 'debug';
import { usePaymentRedirect } from '../../../hooks/use-redirect';
import { submitFormInIFrame } from '../utils';
import { useNetworkErrorContext } from '../../network-error-context';
import { useFormVariantContext } from '../../form-variant-context';
import { usePaysterPaymentContext } from '../../payster-payment-context';

export type TScreen =
  | 'PayidScreen'
  | 'PaysterPaymentScreen'
  | 'ProcessingInProgressScreen'
  | 'OrderProcessingScreen'
  | 'CardScreen'
  | 'PaysterEnterEmailScreen'
  | 'SuccessScreen'
  | 'PayidDeclinedScreen'
  | 'PaysterDeclinedScreen'
  | 'DeclinedScreen'
  | 'PaymentTypesScreen'
  | 'FatalErrorScreen'
  | 'BankTransferPBScreen'
  | 'P2PgejpyScreen'
  | 'PayidTopPayEnterCredsScreen'
  | 'PayidTopPayConfirmationScreen'
  | 'P2PPCRCardScreen'
  | 'P2PPCRPhoneRubScreen'
  | 'P2PPCRPhoneAznScreen'
  | 'P2PPCRIbanScreen';

type TPaymentPageContext = {
  /** Состояние раскрытия блока деталей заказа */
  isOpenedOrderDetails: boolean;
  setOpenedOrderDetails: React.Dispatch<React.SetStateAction<boolean>>;
  screen: TScreen;
  setScreen: Dispatch<SetStateAction<TScreen>>;
};

const log = createLogger('PaymentPageContext');

// Типы платежей для которых свой флоу
const excludePaymentMethod: TAllMethods[] = [
  'payid',
  'p2pphonepcrrub',
  'p2pphonepcrazn',
  'p2pcardpcr',
  'p2pibanpcr',
  ...PAYSTER_FLOW_METHODS,
] as const;

const PaymentPageContext = createContext<TPaymentPageContext>(
  {} as TPaymentPageContext,
);

export const PaymentPageContextProvider: FCC = ({ children }) => {
  const { session, dataUpdatedAt } = usePaymentSessionState();
  const sessionId = session?.id ?? 'ooops';
  const payment = session?.payment;
  const patchSession = usePaymentPatchSessionState();
  const { error: paymentError } = useNetworkErrorContext();
  const { isCreateApmPaymentLoading } = useCreateApmPayment();
  const state = session?.payment?.state;
  const method = usePaymentMethod();
  const [screen, setScreen] = useState<TScreen>('PaymentTypesScreen');

  const [isOpenedOrderDetails, setOpenedOrderDetails] =
    useState<boolean>(false);
  useEffect(() => {
    if (paymentError && paymentError.errorDisplayType === 'failedScreen') {
      // Делаем перенаправление на failed экран
      const { errorPayload } = paymentError;
      if (errorPayload && session) {
        patchSession({
          ...errorPayload.session,
          id: session.id,
        });
      }
    }
  }, [paymentError, session, patchSession]);

  const selectedScreen = useMemo<TScreen>(() => {
    if (state === 'processingFlowInProgress' || isCreateApmPaymentLoading) {
      // Экран для Payid и Payster находится в состоянии processingFlowInProgress
      if (method === 'payid') return 'PayidScreen';
      if (method && PAYSTER_FLOW_METHODS.includes(method)) {
        return 'PaysterPaymentScreen';
      }
      if (method === 'p2pphonepcrrub') return 'P2PPCRPhoneRubScreen';
      if (method === 'p2pphonepcrazn') return 'P2PPCRPhoneAznScreen';
      if (method === 'p2pcardpcr') return 'P2PPCRCardScreen';
      if (method === 'p2pibanpcr') return 'P2PPCRIbanScreen';
      if (method === 'payidtpaud') return 'PayidTopPayConfirmationScreen';
      return 'ProcessingInProgressScreen';
    }

    if (state === 'pending' || state === 'redirect')
      return 'OrderProcessingScreen';
    if (state === 'authorized' || state === 'preAuthorized')
      return 'SuccessScreen';

    if (state === 'tryAnotherPayment' || state === 'ppTriesNumberIsExceeded') {
      if (method === 'payid') return 'PayidDeclinedScreen';
      if (method && PAYSTER_FLOW_METHODS.includes(method)) {
        return 'PaysterDeclinedScreen';
      }
      return 'DeclinedScreen';
    }

    if (state === 'error') {
      return 'FatalErrorScreen';
    }

    return 'PaymentTypesScreen';
  }, [state, method, isCreateApmPaymentLoading]);

  /**
   * Реагируем на метку времени обновления сессии, так как может получиться ситуация,
   * когда первая попытка платежа была совершена со статусом tryAnotherPayment,
   * и мы вернулись на экран, где заполняли данные карты, к примеру. Тогда,
   * если следующая попытка платежа была так же неудачна, то у нас не изменится статус и метод,
   * но нам необходимо отреагировать на это действие показав другой экран
   */
  useEffect(() => {
    setScreen(selectedScreen);
  }, [selectedScreen, dataUpdatedAt]);

  useEffect(() => {
    log('active screen %s', screen);
  }, [screen]);

  usePaymentRedirectState(payment);
  usePaymentProcessingFlowInProgressState(payment);
  usePaymentPendingState(payment, sessionId);
  usePaymentNoPaymentsFoundState(payment, setScreen, screen);

  const ctx: TPaymentPageContext = useMemo(() => {
    return {
      isOpenedOrderDetails,
      setOpenedOrderDetails,
      screen,
      setScreen,
    };
  }, [screen, isOpenedOrderDetails]);

  useEffect(() => {
    log('%o', ctx);
  }, [ctx]);

  return (
    <PaymentPageContext.Provider value={ctx}>
      {children}
    </PaymentPageContext.Provider>
  );
};

/**
 * Обработка состояний платежа
 * @link - https://payler.atlassian.net/wiki/spaces/PAYL/pages/562233390/Payment+Page+SPA
 * */
// region payments states
/**
 * Обработка состояния платежа `redirect`
 * */
const usePaymentRedirectState = (payment: TPaymentDto | undefined) => {
  const redirect = usePaymentRedirect();
  useEffect(() => {
    if (payment?.state !== 'redirect' || !payment?.action) {
      return;
    }

    if (payment.action.redirect) {
      // Перенаправление на страницу банка
      redirect();
    }
  }, [payment?.action, payment?.state, redirect]);
};

/**
 * Обработка состояния платежа `processingFlowInProgress`
 * */
const usePaymentProcessingFlowInProgressState = (
  payment: TPaymentDto | undefined,
) => {
  const redirect = usePaymentRedirect();
  const { confirmPayment, isConfirmPaymentLoading } = useConfirmPayment();

  useEffect(() => {
    if (payment?.state !== 'processingFlowInProgress' || !payment?.action) {
      return;
    }

    if (payment.method && excludePaymentMethod.includes(payment.method)) {
      return;
    }

    if (payment.action.redirect) {
      // Перенаправление на страницу банка
      redirect();
    } else if (payment.action.threeDSMethod && !isConfirmPaymentLoading) {
      // Авторизация в скрытом iframe
      submitFormInIFrame(payment.action.threeDSMethod, confirmPayment);
    }
  }, [
    confirmPayment,
    isConfirmPaymentLoading,
    payment?.action,
    payment?.method,
    payment?.state,
    redirect,
  ]);
};

/**
 * Обработка состояния платежа `pending`
 * */
let timeReturnToMerchant: NodeJS.Timeout | undefined;
let timeoutInvalidationSessionQuery: NodeJS.Timeout | undefined;
const usePaymentPendingState = (
  payment: TPaymentDto | undefined,
  sessionId: string | undefined,
) => {
  const redirect = usePaymentRedirect();
  const invalidatePaymentSession = useInvalidatePaymentSession();

  useEffect(() => {
    if (payment?.state !== 'pending') {
      if (timeReturnToMerchant) {
        clearTimeout(timeReturnToMerchant);
      }
      return;
    }

    if (sessionId) {
      timeoutInvalidationSessionQuery = setTimeout(
        invalidatePaymentSession,
        2_000,
      );
      // Отправляем на страницу мерчанта, если сессия присылает статус 'pending' 30 секунд
      if (!timeReturnToMerchant) {
        timeReturnToMerchant = setTimeout(redirect, 30_000);
      }
    }

    return () => {
      if (timeoutInvalidationSessionQuery) {
        clearTimeout(timeoutInvalidationSessionQuery);
      }
    };
  }, [payment?.state, redirect, invalidatePaymentSession, sessionId]);
};

/**
 * Обработка состояния платежа `noPaymentsFound`
 * */
const usePaymentNoPaymentsFoundState = (
  payment: TPaymentDto | undefined,
  onSetScreen: Dispatch<SetStateAction<TScreen>>,
  screen: TScreen,
) => {
  const availablePaymentMethods = useAvailablePaymentMethods();
  const { createApmPayment } = useCreateApmPayment();
  const { setFormVariant } = useFormVariantContext();
  const { setPaysterPaymentMethod } = usePaysterPaymentContext();

  const setScreen = useCallback(
    (screen: TScreen) => {
      log(`set screen ${screen}`);
      if (typeof onSetScreen === 'function') {
        onSetScreen(screen);
      }
    },
    [onSetScreen],
  );

  useEffect(() => {
    const allPaymentMethods = payment?.page?.ui?.payMethods;
    const hasPayster = PAYSTER_FLOW_METHODS.some(
      (method) => allPaymentMethods?.[method],
    );
    const customFormScreen = getCustomPaymentFormScreen(
      availablePaymentMethods,
    );

    if (
      payment?.state !== 'noPaymentsFound' ||
      screen !== 'PaymentTypesScreen'
    ) {
      return;
    }

    if (availablePaymentMethods.length !== 1) {
      return;
    }

    if (customFormScreen) {
      setScreen(customFormScreen);
    } else if (!hasPayster) {
      setScreen('OrderProcessingScreen');
      createApmPayment(
        availablePaymentMethods[0] as Exclude<TApmMethods, 'payster'>,
      );
    } else {
      setFormVariant('payster');
      setPaysterPaymentMethod(
        availablePaymentMethods[0] as keyof Pick<
          TPageUiPayMethods,
          'paysterfmaud' | 'paysterhcaud' | 'payster'
        >,
      );
      setScreen('PaysterEnterEmailScreen');
    }
  }, [
    setPaysterPaymentMethod,
    availablePaymentMethods,
    createApmPayment,
    payment?.page?.ui?.payMethods,
    payment?.state,
    setFormVariant,
    setScreen,
  ]);
};

//endregion

/**
 * Метод для сопоставления методов и форм для более удобного масштабирования
 */
const getCustomPaymentFormScreen = (
  availablePaymentMethods: (keyof TPageUiPayMethods)[],
) => {
  const paymentMethod = availablePaymentMethods[0];
  const paymentMethodsWithCustomFlow: Partial<
    Record<keyof TPageUiPayMethods, TScreen>
  > = {
    cards: 'CardScreen',
    banktransferpb: 'BankTransferPBScreen',
    p2pgejpy: 'P2PgejpyScreen',
    payidtpaud: 'PayidTopPayEnterCredsScreen',
  };

  if (!paymentMethod) {
    return null;
  }

  return paymentMethodsWithCustomFlow[paymentMethod];
};

export const usePaymentPageContext = () => useContext(PaymentPageContext);
