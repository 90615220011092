import createLogger from 'debug';
import { memo, useEffect } from 'react';
import { usePaymentSessionState } from '../../hooks/payment/session';
import { useFormVariantContext } from '../../context/form-variant-context';
import { FullPageLoader, NotFound } from '@payler/payment-page-ui-shared';
import { Outlet } from 'react-router-dom';
import { PAYSTER_FLOW_METHODS } from '@payler/payment-page-api-gate';

const log = createLogger('PaymentScreen');

export const PaymentScreen = memo(() => {
  const { session, isLoading } = usePaymentSessionState();
  const { setFormVariant } = useFormVariantContext();

  useEffect(() => {
    if (session) {
      if (
        session.payment?.method &&
        PAYSTER_FLOW_METHODS.includes(session.payment?.method)
      ) {
        log('set form variant %s', 'payster');
        setFormVariant('payster');
      } else {
        log('set form variant %s', 'default');
        setFormVariant('default');
      }
    }
  }, [session, setFormVariant]);

  if (isLoading && !session) return <FullPageLoader />;
  if (!session) return <NotFound />;

  return <Outlet />;
});
