import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TSavedCard } from '@payler/payment-page-api-gate';

type TCardsContext = {
  isEditMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  removeId: string | undefined;
  setRemoveId: Dispatch<SetStateAction<string | undefined>>;
  /** Выбранная сохранённая карта */
  selectedSavedCard: TSavedCard | undefined;
  setSelectedSavedCard: React.Dispatch<
    React.SetStateAction<TSavedCard | undefined>
  >;
};

const CardsContext = createContext<TCardsContext>({} as TCardsContext);

type TCardsContextProvider = {
  isEditModeInitial?: boolean;
};

export const CardsContextProvider: FCC<TCardsContextProvider> = ({
  children,
  isEditModeInitial = false,
}) => {
  const [isEditMode, setEditMode] = useState<boolean>(isEditModeInitial);
  const [removeId, setRemoveId] = useState<string | undefined>();
  const [selectedSavedCard, setSelectedSavedCard] = useState<
    TSavedCard | undefined
  >();
  const ctx: TCardsContext = useMemo(() => {
    return {
      isEditMode,
      setEditMode,
      removeId,
      setRemoveId,
      selectedSavedCard,
      setSelectedSavedCard,
    };
  }, [isEditMode, removeId, selectedSavedCard]);

  // Если отключили режим редактирования, то сбрасываем идентификатор записи на подтверждении
  useEffect(() => {
    if (!isEditMode && removeId) {
      setRemoveId(undefined);
    }
  }, [isEditMode, removeId]);

  return <CardsContext.Provider value={ctx}>{children}</CardsContext.Provider>;
};

export const useCardsContext = () => useContext(CardsContext);
