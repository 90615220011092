import { Api } from '@payler/payment-page-api-gate';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useNetworkErrorContext } from './network-error-context';

export type TPaymentPageEnv = {
  baseURL: string;
  doRedirect: boolean;
};

export type TPaymentPageConfigContext = TPaymentPageEnv & {
  api: Api;
};

const PaymentPageContext = createContext<TPaymentPageConfigContext>(
  {} as TPaymentPageConfigContext
);

export const PaymentPageConfigContextProvider: FCC<{
  env: TPaymentPageEnv;
}> = ({ children, env }) => {
  const { setError, error } = useNetworkErrorContext();
  useEffect(() => {
    /**
     * Если мы получили ошибку и она имеет тип fullPage, то мы должны кинуть исключение прямо в рендере
     * для того, чтобы компонент ErrorBoundary отловил ее
     */
    if (
      error &&
      'errorDisplayType' in error &&
      error?.errorDisplayType === 'fullPage'
    ) {
      throw error;
    }
  }, [error]);

  const ctx = useMemo(() => {
    const api = new Api(env.baseURL, setError);
    return { ...env, api };
  }, [env, setError]);
  return (
    <PaymentPageContext.Provider value={ctx}>
      {children}
    </PaymentPageContext.Provider>
  );
};

export const useApi = () => useContext(PaymentPageContext).api;
export const useDoRedirectFlag = () =>
  useContext(PaymentPageContext).doRedirect;
