import { TConfirmPaymentFunc } from '../../../hooks/payment';
import {
  TCompletionIndicator,
  TIndicatorDto,
  TThreeDSMethodDto,
} from '@payler/payment-page-api-gate';
import axios, { AxiosResponse } from 'axios';

const NUMBER_OF_MILLISECONDS_TO_REQUEST_STATUS = 10_000;

const fetchCompletionIndicator = async (
  url: string,
  transId: string,
  confirmPayment: TConfirmPaymentFunc,
) => {
  let intervalTimerId: NodeJS.Timer | undefined;
  let promiseResolvingTimeoutId: NodeJS.Timeout | undefined;

  setTimeout(
    () => clearInterval(intervalTimerId),
    NUMBER_OF_MILLISECONDS_TO_REQUEST_STATUS,
  );

  // Запускаем запросы, нам не важно тут знать успешно или нет прошёл tds, это мы узнаем в ответе confirm
  const indicatorResult: TIndicatorDto | undefined = await new Promise<
    TIndicatorDto | undefined
  >((resolve) => {
    intervalTimerId = setInterval(async () => {
      const response = await fetchIndicator(url);

      const compInd =
        response?.data?.session?.payment?.attempt?.threeDSMethod?.compInd;

      if (compInd === 'Y' || compInd === 'U') {
        resolve(response?.data);
        clearTimeout(promiseResolvingTimeoutId);
        clearInterval(intervalTimerId);
      }

      if (!promiseResolvingTimeoutId) {
        /**
         * Если данные так и не получены, то резолвим промис
         */
        promiseResolvingTimeoutId = setTimeout(() => {
          resolve(response?.data);
          clearTimeout(promiseResolvingTimeoutId);
        }, NUMBER_OF_MILLISECONDS_TO_REQUEST_STATUS + 1);
      }
    }, 1000);
  });

  const completionIndicator = indicatorResult?.session?.payment?.attempt
    ?.threeDSMethod?.compInd as TCompletionIndicator;
  const attemptId = indicatorResult?.session?.payment?.attempt?.id;

  if (!completionIndicator || !attemptId) {
    throw new Error('No attempt id or completion indicator.');
  }

  confirmPayment(attemptId, transId, completionIndicator, confirmSuccess);
};

const fetchIndicator = async (
  url: string,
): Promise<AxiosResponse<TIndicatorDto> | null> => {
  try {
    return await axios.get<TIndicatorDto>(url);
  } catch (e) {
    // ошибку игнорируем, продолжаем опросы, реагируем как буд-то получили N
    /*
    TODO: позже на живых примерах понаблюдать, возможно есть виды ответов,
     при которых дальше бесполезно продолжать опросы и нужно сообщать об этой ошибке.
     В таком случае дальнейшее выполнение остановить и через setError установить ошибку для отображения
     */
  }

  return null;
};

const confirmSuccess = () => {
  removeIFrames();
  removeForms();
};
const removeIFrames = () => {
  // убираем iframe-ы из DOM
  document.getElementById('threeDSMethodIframe')?.remove();
};

const removeForms = () => {
  // убираем формы из DOM
  document.getElementById('iframe-form')?.remove();
  document.getElementById('self-form')?.remove();
};

const submitFormInIFrame = (
  data: TThreeDSMethodDto,
  confirmPayment: TConfirmPaymentFunc,
) => {
  if (!data.url) {
    throw new Error('No url for Three DS Method');
  }
  if (!data.encodedParams) {
    throw new Error('No parameters for Three DS Method');
  }
  const name = 'threeDSMethodIframe';
  const iframe = document.createElement('iframe');
  iframe.setAttribute('name', name);
  iframe.setAttribute('id', name);
  iframe.setAttribute('hidden', 'hidden');
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.display = 'none';
  const form = document.createElement('form');
  form.setAttribute('id', 'iframe-form');
  form.setAttribute('target', name);
  form.setAttribute('action', data.url);
  form.setAttribute('method', 'POST');

  const input = document.createElement('input');
  input.setAttribute('name', 'threeDSMethodData');
  input.setAttribute('value', data.encodedParams);
  input.setAttribute('hidden', 'hidden');
  form.appendChild(input);
  iframe.appendChild(form);
  document.body.appendChild(iframe);

  iframe.onload = async () => {
    if (!data.urlProcessingStatus) {
      throw new Error('No url processing status');
    }
    if (!data['3DSServerTransID']) {
      throw new Error('No 3DSServerTransID in parameters');
    }

    try {
      await fetchCompletionIndicator(
        data.urlProcessingStatus,
        data['3DSServerTransID'],
        confirmPayment,
      );
    } catch (_: unknown) {
      throw new Error('Indicator threeDSMethod completion failed');
    }
  };

  form.submit();
};

export { submitFormInIFrame };
