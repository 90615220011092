import { createContext, useContext, useMemo } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

export type TLayout = 'desktop' | 'mobile';

type TLayoutContext = {
  layout: TLayout;
  isMobile: boolean;
  isDesktop: boolean;
};

const LayoutContext = createContext<TLayoutContext>({
  layout: 'desktop',
  isMobile: false,
  isDesktop: true,
});

export const LayoutContextProvider: FCC = ({ children }) => {
  const [isMobile = false, isDesktop = false] = useMediaQuery([
    `(max-width: 575.98px)`,
    `(min-width: 576px)`,
  ]);

  const ctx = useMemo(() => {
    let layout: TLayout = 'mobile';
    switch (true) {
      case isMobile: {
        layout = 'mobile';
        break;
      }
      case isDesktop: {
        layout = 'desktop';
        break;
      }
    }

    return {
      layout,
      isMobile,
      isDesktop,
    };
  }, [isDesktop, isMobile]);
  return (
    <LayoutContext.Provider value={ctx}>{children}</LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
