import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../context/config-context';

export const useDeleteSavedCardMutate = () => {
  const api = useApi();

  return useMutation({
    mutationFn: async (id: string) => {
      return await api.deleteSavedCard({
        session: {
          payment: {
            method: {
              card: {
                savedCards: {
                  id,
                },
              },
            },
          },
        },
      });
    },
  });
};
