import { TPageUiPayMethods } from '@payler/payment-page-api-gate';
import { createContext, useContext, useMemo, useState } from 'react';
import createLogger from 'debug';

const log = createLogger('PaysterPaymentContext');

type TPaysterMethod = keyof Pick<
  TPageUiPayMethods,
  'paysterfmaud' | 'paysterhcaud' | 'payster'
>;

type TPaysterPaymentContext = {
  paysterPaymentMethod: TPaysterMethod | undefined;
  setPaysterPaymentMethod(method: TPaysterMethod): void;
};

const PaysterPaymentContext = createContext({} as TPaysterPaymentContext);

export const PaysterPaymentContextProvider: FCC = ({ children }) => {
  const [paysterPaymentMethod, setPaysterPaymentMethod] =
    useState<TPaysterMethod>();

  const ctx = useMemo<TPaysterPaymentContext>(() => {
    return {
      paysterPaymentMethod,
      setPaysterPaymentMethod: (newMethod) => {
        log('set payster payment method %s', newMethod);
        setPaysterPaymentMethod(newMethod);
      },
    };
  }, [paysterPaymentMethod]);

  return (
    <PaysterPaymentContext.Provider value={ctx}>
      {children}
    </PaysterPaymentContext.Provider>
  );
};

export const usePaysterPaymentContext = () => useContext(PaysterPaymentContext);
