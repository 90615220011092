import { createContext, useContext, useMemo, useState } from 'react';
import { MayBe } from '@payler/utils';
import { TNetworkErrorInfo } from '@payler/payment-page-api-gate';

type TErrorContext = {
  error: MayBe<TNetworkErrorInfo>;
  setError: (error: MayBe<TNetworkErrorInfo>) => void;
};

const NetworkErrorContext = createContext<TErrorContext>({
  error: null,
  setError: () => {
    /// noop
  },
});

export const NetworkErrorContextProvider: FCC = ({ children }) => {
  const [error, setError] = useState<MayBe<TNetworkErrorInfo>>();
  const ctx = useMemo<TErrorContext>(
    () => ({
      error,
      setError,
    }),
    [error]
  );
  return (
    <NetworkErrorContext.Provider value={ctx}>
      {children}
    </NetworkErrorContext.Provider>
  );
};

export const useNetworkErrorContext = () =>
  useContext<TErrorContext>(NetworkErrorContext);
