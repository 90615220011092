import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { VStack } from '@chakra-ui/react';
import { ThinDivider } from '../Divider/ThinDivider';
import { DropdownOption } from './DropdownOption';
import { TDropdownProps } from './Dropdown';

type TDropdownOptionsProps = {
  options: TDropdownProps['options'];
  selected: Array<string | number> | null;
  onOptionClick: (val: string | number) => void;
  multi?: boolean;
  selectAllLabel?: string;
  onSelectAllClick?: VoidFunction;
  allSelected?: boolean;
  selectAllOptionsBehaviour?: 'selected' | 'unselected';
  alignOptionsCenter?: boolean;
  isSearchable?: boolean;
  testId?: string;
};

type OptionsRefCollection = Record<number, HTMLDivElement | null>;

export const DropdownOptions = ({
  options,
  selected,
  onOptionClick,
  multi,
  selectAllLabel,
  onSelectAllClick,
  allSelected,
  selectAllOptionsBehaviour,
  alignOptionsCenter,
  isSearchable,
  testId,
}: TDropdownOptionsProps) => {
  const optionsRefCollection = useRef<OptionsRefCollection>({});
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const setOptionRef =
    (index: number) =>
    (node: HTMLDivElement | null): void => {
      if (node && !isEqual(node, optionsRefCollection.current[index])) {
        optionsRefCollection.current[index] = node;
      }
    };

  useEffect(() => {
    const selectedOption = optionsRefCollection.current[selectedOptionIndex];
    if (selectedOption) {
      selectedOption.scrollIntoView({ block: 'nearest' });
    }
  }, [selectedOptionIndex]);

  const listener = useCallback(
    (e: KeyboardEvent): void => {
      const lastIndex = options.length - 1;

      if (e.key === 'ArrowDown') {
        setSelectedOptionIndex((prevState) => {
          if (prevState + 1 <= lastIndex) {
            return prevState + 1;
          }
          return 0;
        });
      }

      if (e.key === 'ArrowUp') {
        setSelectedOptionIndex((prevState) => {
          if (prevState - 1 >= 0) {
            return prevState - 1;
          }
          return lastIndex;
        });
      }

      if (e.key === 'Enter') {
        setSelectedOptionIndex((prevState) => {
          const option = options[prevState];
          if (option?.disabled) {
            onOptionClick(option.value);
          }
          return prevState;
        });
      }
    },
    [onOptionClick, options, setSelectedOptionIndex]
  );

  useEffect(() => {
    if (isSearchable) {
      window.document.addEventListener('keydown', listener);
    }
    return () => {
      window.document.removeEventListener('keydown', listener);
    };
  }, [options, listener, isSearchable]);

  return (
    <VStack
      alignItems="stretch"
      divider={<ThinDivider />}
      spacing={0}
      userSelect="none"
    >
      {multi && selectAllLabel && onSelectAllClick && (
        <DropdownOption
          multi
          option={{ label: selectAllLabel, value: '#toggle-selected' }}
          onClick={onSelectAllClick}
          selected={allSelected}
          testId={`${testId}-all`}
        />
      )}
      {options.map((x, i) => {
        let checked: boolean;
        if (!allSelected) {
          checked = selected?.includes(x.value) ?? false;
        } else {
          switch (selectAllOptionsBehaviour) {
            case 'unselected':
              checked = false;
              break;
            case 'selected':
            default:
              checked = selected?.includes(x.value) ?? false;
              break;
          }
        }
        return (
          <div
            ref={setOptionRef(i)}
            key={`${i}_${x.value}`}
            data-testid={`${testId}-${
              checked ? 'checked' : 'unchecked'
            }-option`}
          >
            <DropdownOption
              option={x}
              multi={multi}
              onClick={(val) => {
                setSelectedOptionIndex(i);
                onOptionClick(val);
              }}
              selected={checked}
              alignCenter={alignOptionsCenter}
              wrapperBgColor={getWrapperBgColor(
                checked,
                selectedOptionIndex,
                isSearchable,
                i
              )}
            />
          </div>
        );
      })}
    </VStack>
  );
};

function getWrapperBgColor(
  checked: boolean,
  selectedOptionIndex: number,
  isSearchable: boolean | undefined,
  index: number
): string {
  if (isSearchable) {
    return index === selectedOptionIndex ? 'violet.100' : 'secondary.500';
  }
  return checked ? 'violet.100' : 'secondary.500';
}
