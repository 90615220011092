import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import createLogger from 'debug';

const log = createLogger('FormVariantContext');
type TFormVariantContext = {
  formVariant: TFormVariant;
  setFormVariant: Dispatch<SetStateAction<TFormVariant>>;
};
type TFormVariant = 'default' | 'payster' | null;
const FormVariantContext = createContext<TFormVariantContext>(
  {} as TFormVariantContext
);

export const FormVariantContextProvider: FCC = ({ children }) => {
  const [formVariant, setFormVariant] = useState<TFormVariant>(null);

  const ctx = useMemo(
    () => ({
      formVariant,
      setFormVariant,
    }),
    [formVariant]
  );
  useEffect(() => {
    log('formVariant %s', formVariant);
  }, [formVariant]);
  return (
    <FormVariantContext.Provider value={ctx}>
      {children}
    </FormVariantContext.Provider>
  );
};

export const useFormVariantContext = () => useContext(FormVariantContext);
