import { Text, TextProps } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

const year = new Date().getFullYear();
export const CopyrightBlock = (props: TextProps) => {
  return (
    <Text
      textStyle={TextStyles.Caption12Regular}
      color="primary.300"
      {...props}
    >
      © Payler ltd. {year}
    </Text>
  );
};
