import { extendTheme } from '@chakra-ui/react';
import { theme as paylerTheme } from '@payler/ui-theme';
import { CheckboxStyles } from './checkbox';
import { InputStyles } from './inputs';

export const theme = extendTheme({
  ...paylerTheme,
  config: {
    cssVarPrefix: 'payment-page',
    initialColorMode: 'light',
  },
  breakpoints: {
    mobile: '576px',
    desktop: '1024px',
  },
  components: {
    ...paylerTheme.components,
    Input: InputStyles,
    Checkbox: CheckboxStyles,
  },
  shadows: {
    card: '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)',
  },
});
