import { HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CopyComponent } from './copy-component';

const ellipsisProps = {
  maxWidth: 'full',
  overflow: 'hidden',
} as const;

export const DetailsItem = ({
  label,
  value,
  canCopy,
}: {
  label: string | undefined;
  value: string | undefined;
  canCopy?: boolean;
}) => {
  if (!value || !label) {
    return null;
  }

  return (
    <VStack alignItems="start" spacing={0.5}>
      <Text
        textStyle={TextStyles.Caption12Regular}
        color="primary.350"
        {...ellipsisProps}
      >
        {label}
      </Text>
      <HStack w="full" spacing={1}>
        <Text
          textStyle={TextStyles.BodyUI16Medium}
          {...ellipsisProps}
          fontWeight={500}
        >
          {value}
        </Text>
        {canCopy && <CopyComponent value={value} />}
      </HStack>
    </VStack>
  );
};
