import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import createLogger from 'debug';
import Duration from 'dayjs/plugin/duration';
import { CURRENCIES } from '../consts';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(Duration);
dayjs.extend(localizedFormat);
const log = createLogger('useLanguageFeatures');

export type TConvertDateStringsFunc = (
  value?: string | null | undefined
) => string | undefined;

export const useLanguageFeatures = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return useMemo(() => {
    const URL_DATE_FORMAT = 'YYYY-MM-DD';
    // TODO сделать UI_DATE_FORMAT зависимым от языка (когда появится список)
    const UI_DATE_FORMAT = 'MM/DD/YYYY';

    const numFormatter = (options?: Intl.NumberFormatOptions) =>
      new Intl.NumberFormat(language, options);

    const numShortFormatter = new Intl.NumberFormat(language, {
      notation: 'compact',
    });

    const getCountDecimals = (currency?: string) =>
      CURRENCIES.find(({ currency: curr }) => currency === curr)?.decimals ?? 2;

    // Добавление точки для числа на основании валюты
    const addDecimals = (value: number, currency?: string): number =>
      value / Math.pow(10, getCountDecimals(currency));

    const formatNumber = (value: number, options?: Intl.NumberFormatOptions) =>
      numFormatter(options).format(value);

    const formatAsPercentage = (value: number) => {
      return formatNumber(value, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      });
    };

    const formatNumberShort = numShortFormatter.format;

    const formatAmountByCurrency = (amount: number, currency?: string) =>
      `${formatNumber(addDecimals(amount, currency), {
        minimumFractionDigits: getCountDecimals(currency),
        maximumFractionDigits: getCountDecimals(currency),
      })} ${currency ?? ''}`;

    const formatServerDate = (value?: string) =>
      value ? dayjs(value).format('L') : null;
    const formatServerTime = (value?: string) =>
      value ? dayjs(value).format('LTS') : null;
    const formatServerDateTime = (value?: string) =>
      value ? dayjs(value).format('lll') : null;
    const formatMillisecondsToHMS = (value?: number) =>
      value
        ? dayjs
            .duration(value, 'milliseconds')
            .format(
              `H[${t('common:h')}] m[${t('common:m')}] s[${t('common:s')}]`
            )
            .replace(/\b0+\S+\s*/gi, '')
            .trim()
        : null;
    const urlToUiDate: TConvertDateStringsFunc = (value) => {
      try {
        return value
          ? dayjs(value, URL_DATE_FORMAT).format(UI_DATE_FORMAT)
          : undefined;
      } catch (e) {
        log('urlToUiDate error: %O, value was: %s', e, value);
      }
      return undefined;
    };
    const uiToUrlDate: TConvertDateStringsFunc = (value) => {
      return value
        ? dayjs(value, UI_DATE_FORMAT).format(URL_DATE_FORMAT)
        : undefined;
    };
    const urlToApiDate: TConvertDateStringsFunc = (value) => {
      try {
        return value
          ? dayjs(value, URL_DATE_FORMAT).toDate().toISOString()
          : undefined;
      } catch (e) {
        log('urlToApiDate error: %O, value was: %s', e, value);
      }
      return undefined;
    };
    const dateDiffDuration = (
      date: dayjs.Dayjs | Date,
      date2: dayjs.Dayjs | Date
    ) => {
      return dayjs.duration(
        dayjs(date).unix() - dayjs(date2).unix(),
        'seconds'
      );
    };
    const compare = new Intl.Collator(language).compare;

    return {
      language,
      urlDateFormat: URL_DATE_FORMAT,
      uiDateFormat: UI_DATE_FORMAT,
      urlToUiDate,
      uiToUrlDate,
      urlToApiDate,
      formatNumber,
      formatAsPercentage,
      formatNumberShort,
      formatServerDate,
      formatServerTime,
      formatServerDateTime,
      dateDiffDuration,
      formatAmountByCurrency,
      formatMillisecondsToHMS,
      compare,
    };
  }, [language, t]);
};
