import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { i18nResources } from '@payler/payment-page-ui-gate';

const onResize = () => {
  document.documentElement.style.setProperty(
    '--window-inner-height',
    `${window.innerHeight}px`
  );
};

onResize();
window.addEventListener('resize', onResize);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'common',
    resources: i18nResources,
    fallbackLng: 'en',
  })
  .then(() => {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://9db106046275cd6287b432df185c98d2@o4504331747262464.ingest.sentry.io/4506103900798976',
        integrations: [new BrowserTracing(), new Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
    return root.render(<App />);
  });
