import { ComponentStyleConfig } from '@chakra-ui/react';
import { generalColors } from '../colors';
import { TextStyles } from '../textStyles';

export const PinStyles: ComponentStyleConfig = {
  baseStyle: {
    marginStart: 1.5,
    border: 'none',
    textStyle: TextStyles.BodyUI16Semibold,
    bg: 'primary.25',
    boxShadow: 'pin',
    _hover: {
      boxShadow: 'pinHover',
    },
    _focus: {
      boxShadow: `pinFocus`,
    },
    ':first-of-type': {
      marginStart: 0,
    },
  },

  sizes: {
    md: {
      w: [5, 6],
      h: [6, 7],
    },
  },
  defaultProps: {
    focusBorderColor: generalColors.hoverForBoxShadow,
    size: 'md',
  },
};
