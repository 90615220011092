import { useMemo } from 'react';
import { TBrowser } from '@payler/payment-page-api-gate';

export const useBrowser = () => {
  const browser: TBrowser = useMemo(
    () => ({
      language: navigator.language,
      userAgent: navigator.userAgent,
      javaEnabled: navigator.javaEnabled?.() ?? false,
      javascriptEnabled: true,
      screen: {
        height: window.screen.height.toString(),
        width: window.screen.width.toString(),
      },
      colorDepth: window.screen.colorDepth.toString(),
      tz: new Date().getTimezoneOffset().toString(),
    }),
    []
  );

  return browser;
};
