import { Box, ChakraProps, Divider, VStack } from '@chakra-ui/react';

export const DividedFooter: FCC<ChakraProps> = ({ children, ...rest }) => {
  return (
    <Box w="full" px={2} {...rest}>
      <Divider width="100vw" right={0} position="absolute" />
      <VStack w="full" h="full" spacing={2} pt={3}>
        {children}
      </VStack>
    </Box>
  );
};
