import { createStylesContext } from '@chakra-ui/react';

const [StyleProvider] = createStylesContext('RequiredIndicator');

export const RequiredIndicatorStyleProvider: FCC = ({ children }) => {
  return (
    <StyleProvider
      value={{
        requiredIndicator: { color: 'red.400', ml: 0.5 },
      }}
    >
      {children}
    </StyleProvider>
  );
};
