import { Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useLayout } from '../../context/layout-context';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { FC } from 'react';
import { MayBe } from '@payler/utils';

const ellipsisProps = {
  maxW: 'full',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
} as const;

type TOrderAmountHeadingProps = {
  amount: MayBe<number>;
  currency: MayBe<string>;
};
export const OrderAmountHeading: FC<TOrderAmountHeadingProps> = ({
  amount,
  currency,
}) => {
  const { isDesktop } = useLayout();
  const { formatAmountByCurrency } = useLanguageFeatures();

  return (
    <Text
      textStyle={isDesktop ? TextStyles.h1 : TextStyles.h2}
      {...ellipsisProps}
    >
      {formatAmountByCurrency(amount ?? 0, currency ?? '')}
    </Text>
  );
};
