import { DifferentColors } from '../types';

export const lightCPColors: DifferentColors = {
  primary: {
    500: '#080808',
    400: 'rgba(8, 8, 8, 0.68)',
    350: 'rgba(8, 8, 8, 0.54)',
    300: 'rgba(8, 8, 8, 0.32)',
    200: 'rgba(8, 8, 8, 0.16)',
    100: 'rgba(8, 8, 8, 0.08)',
    50: 'rgba(8, 8, 8, 0.06)',
    25: 'rgba(8, 8, 8, 0.02)',
  },
  secondary: {
    500: '#FFFFFF',
    400: 'rgba(255, 255, 255, 0.68)',
    350: 'rgba(255, 255, 255, 0.54)',
    300: 'rgba(255, 255, 255, 0.32)',
    200: 'rgba(255, 255, 255, 0.16)',
    100: 'rgba(255, 255, 255, 0.08)',
    50: 'rgba(255, 255, 255, 0.06)',
    25: 'rgba(255, 255, 255, 0.02)',
  },
  other: {
    bg: '#FAFAFD',
    menuBG: '#F5F5F8',
  },
};
