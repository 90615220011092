import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useApi } from '../../context/config-context';
import {
  TAllMethods,
  TPageUiPayMethods,
  TSessionDto,
  TSessionResponse,
} from '@payler/payment-page-api-gate';
import { useIdFromUrl } from '@payler/payment-page-ui-shared';

const STALE = 600_000;

const useSessionStateQuery = () => {
  const api = useApi();
  const id = useIdFromUrl();
  if (!id) {
    throw new Error('Session id not found');
  }

  return useQuery(['session', 'state'], () => api.getSessionState(id), {
    refetchOnWindowFocus: false,
    staleTime: STALE,
    structuralSharing: false,
  });
};

export const usePaymentSessionState = () => {
  const { data, error, isLoading, isError, isFetching, dataUpdatedAt } =
    useSessionStateQuery();

  return useMemo(
    () => ({
      session: data?.session,
      error,
      isLoading,
      isError,
      isFetching,
      dataUpdatedAt,
    }),
    [data?.session, error, isLoading, isError, isFetching, dataUpdatedAt]
  );
};

export const usePaymentPatchSessionState = () => {
  const client = useQueryClient();

  return useCallback(
    (session: TSessionDto) => {
      client.setQueriesData<TSessionResponse>(['session', 'state'], (pS) => ({
        ...pS,
        session: {
          ...pS?.session,
          ...session,
        },
      }));
    },
    [client]
  );
};

export const useInvalidatePaymentSession = () => {
  const client = useQueryClient();

  return async () => {
    if (!client.isFetching(['session', 'state'])) {
      await client.invalidateQueries(['session', 'state']);
    }
  };
};

export const usePaymentSavedCards = () => {
  const { session } = usePaymentSessionState();
  return session?.payment?.page?.ui?.payMethods?.cards?.savedCards ?? [];
};
export const usePaymentOrderDetails = () => {
  const { session } = usePaymentSessionState();
  return session?.payment?.page?.ui?.orderDetails;
};
export const usePaymentProviderDetails = () => {
  const { session } = usePaymentSessionState();
  return session?.payment?.page?.ui?.providerDetails ?? [];
};

export const usePaymentMethod = () => {
  const { session } = usePaymentSessionState();
  return session?.payment?.method ?? '';
};

export const useAvailablePaymentMethods = () => {
  const { session } = usePaymentSessionState();
  const allPaymentMethods = session?.payment?.page?.ui?.payMethods;

  return useMemo(
    () =>
      Object.keys(allPaymentMethods ?? {}).filter(
        (method) => allPaymentMethods?.[method as keyof TPageUiPayMethods]
      ) ?? [],
    [allPaymentMethods]
  ) as TAllMethods[];
};

export const useGetPayMethods = () => {
  const { session } = usePaymentSessionState();
  return session?.payment?.page?.ui?.payMethods ?? {};
};

export const usePaymentCustomerData = () => {
  const { session } = usePaymentSessionState();

  return session?.payment?.page?.ui?.customer;
};
