import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

type TTimeoutRefValue = NodeJS.Timeout | null;

type TGlobalTimerContext = {
  getTimerRef(key: string): { current: TTimeoutRefValue };
};

const GlobalTimerContext = createContext<TGlobalTimerContext>(
  {} as unknown as TGlobalTimerContext
);

/**
 * Данный провайдер нужно использовать на самом высоком уровне. Он не должен зависеть от ремаунтов
 * Суть заключается в том, что данный провайдер содержит в себе таймеры, которые не могу потеряться
 * при ремаунте.
 */
export const GlobalTimerContextProvider: FCC = ({ children }) => {
  const timerCollectionRef =
    useRef<Map<string, { current: TTimeoutRefValue }>>();

  // Для того чтобы каждый рендер не происходило создание экземпляра Map
  timerCollectionRef.current = useMemo(() => new Map(), []);

  useEffect(
    () => () => {
      timerCollectionRef.current?.forEach((timer) => {
        if (timer.current) {
          clearTimeout(timer.current);
          clearInterval(timer.current);
        }
      });
    },
    []
  );

  const getTimerRef = useCallback((key: string) => {
    const existTimer = timerCollectionRef.current?.get(key);

    if (existTimer) {
      return existTimer;
    }

    const newTimer = {
      current: null,
    };

    timerCollectionRef.current?.set(key, newTimer);
    return newTimer;
  }, []);

  const ctx = useMemo<TGlobalTimerContext>(
    () => ({ getTimerRef }),
    [getTimerRef]
  );

  return (
    <GlobalTimerContext.Provider value={ctx}>
      {children}
    </GlobalTimerContext.Provider>
  );
};

export const useCreateGlobalTimerRef = (key: string) => {
  const { getTimerRef } = useContext(GlobalTimerContext);

  return getTimerRef(key);
};
