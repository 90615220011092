import { theme as paylerTheme } from '@payler/ui-theme';
import { ComponentStyleConfig } from '@chakra-ui/react';

export const CheckboxStyles: ComponentStyleConfig = {
  ...paylerTheme.components.Checkbox,
  sizes: {
    md: {
      control: {
        w: 2.5,
        h: 2.5,
        borderRadius: '5px',
      },
      icon: {
        w: 2.5,
        h: 1,
      },
    },
  },
  variants: {
    ...paylerTheme.components.Checkbox.variants,
    borderedSquare: {
      control: {
        border: '2px solid',
      },
    },
  },
};
