import React, { FC, isValidElement } from 'react';
import { Circle, Flex, HStack, Icon, Spinner, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { RightIcon } from '../../icons';

type MethodButtonProps = {
  title: React.ReactNode;
  icon: React.ReactElement;
  onClick: () => void;
  isLoading?: boolean;
  dataTestId?: string;
};

export const MethodButton: FC<MethodButtonProps> = ({
  title,
  icon,
  onClick,
  isLoading,
  dataTestId,
}) => {
  return (
    <HStack
      w="full"
      px={3}
      py={2}
      spacing={3}
      onClick={!isLoading ? onClick : undefined}
      cursor="pointer"
      data-testid={dataTestId ?? 'payment-method-wrapper'}
    >
      <HStack w="full" p={0} spacing={2} overflow="hidden">
        <Circle
          size={5}
          bg="primary.25"
          border="1px solid"
          borderColor="black.100"
          color="primary.300"
        >
          {icon}
        </Circle>
        {isValidElement(title) ? (
          title
        ) : (
          <Text
            as={Flex}
            textStyle={TextStyles.BodyText16Medium}
            whiteSpace="nowrap"
            maxWidth="full"
            overflow="hidden"
            textOverflow="ellipsis"
            alignItems="center"
          >
            {title} {isLoading && <Spinner ml={2} size="xs" />}
          </Text>
        )}
      </HStack>
      <Icon as={RightIcon} w={2.5} h={2.5} color="primary.300" />
    </HStack>
  );
};
