import { ReactNode } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { ThinDivider } from '@payler/ui-components';

type TMethodBlockWrapperProps = {
  title?: ReactNode;
};
export const MethodBlockWrapper: FCC<TMethodBlockWrapperProps> = ({
  children,
  title,
}) => {
  return (
    <VStack w="full" spacing={2}>
      <Text w="full" textStyle={TextStyles.h4}>
        {title}
      </Text>
      <VStack
        spacing={0}
        divider={<ThinDivider borderColor="primary.100" />}
        w="full"
        bg="primary.25"
        border="1px solid"
        borderRadius={1}
        borderColor="primary.100"
      >
        {children}
      </VStack>
    </VStack>
  );
};
