import React, { LazyExoticComponent, memo } from 'react';
import { PaymentPageContextProvider } from '../context/payment-page-context/context';
import { useFormVariantContext } from '../context/form-variant-context';
import { CardsContextProvider } from '../context/cards-context';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { PayoutPageContextProvider } from '../context/payout-page-context';
import { PaysterPaymentContextProvider } from '../context/payster-payment-context';

export const FullPageErrorScreen = React.lazy(() =>
  import('../screens/full-page-error-screen').then((module) => ({
    default: module.FullPageErrorScreen,
  })),
);

const PaymentDefaultMainScreen = React.lazy(() =>
  import('../screens/payments/default-main-screen').then((module) => ({
    default: module.DefaultMainScreen,
  })),
);

const PayoutDefaultMainScreen = React.lazy(() =>
  import('../screens/payouts/default-main-screen').then((module) => ({
    default: module.DefaultMainScreen,
  })),
);

const PaysterPaymentMainScreen = React.lazy(() =>
  import('../screens/payments/payster/payster-main-screen').then((module) => ({
    default: module.PaysterMainScreen,
  })),
);

const PaysterPayoutMainScreen = React.lazy(() =>
  import('../screens/payouts/payster/payster-payout-main-screen').then(
    (module) => ({
      default: module.PaysterPayoutMainScreen,
    }),
  ),
);

const paymentForms: Record<
  string,
  LazyExoticComponent<() => EmotionJSX.Element | null>
> = {
  payster: PaysterPaymentMainScreen,
  default: PaymentDefaultMainScreen,
};

const payoutForms: Record<
  string,
  LazyExoticComponent<() => EmotionJSX.Element | null>
> = {
  default: PayoutDefaultMainScreen,
  payster: PaysterPayoutMainScreen,
};

export const PaymentForm = memo(() => {
  const { formVariant } = useFormVariantContext();
  if (!formVariant) return null;

  const Form = paymentForms[formVariant];
  return (
    <PaysterPaymentContextProvider>
      <PaymentPageContextProvider>
        <CardsContextProvider>{Form && <Form />}</CardsContextProvider>
      </PaymentPageContextProvider>
    </PaysterPaymentContextProvider>
  );
});

export const PayoutForm = memo(() => {
  const { formVariant } = useFormVariantContext();
  if (!formVariant || !(formVariant in payoutForms)) return null;

  const Form = payoutForms[formVariant];
  return (
    <PayoutPageContextProvider>{Form && <Form />}</PayoutPageContextProvider>
  );
});
