import { ChakraProvider } from '@chakra-ui/react';
import { useTheme } from '../../hooks/useTheme';
import { GlobalFonts } from '@payler/ui-theme';
import { useEffect, useState } from 'react';

export const ChakraWrapper: FCC = ({ children }) => {
  const themePromise = useTheme();
  const [theme, setTheme] = useState({});
  useEffect(() => {
    themePromise.then(setTheme);
  }, [themePromise]);

  return (
    <ChakraProvider theme={theme}>
      <GlobalFonts />
      {children}
    </ChakraProvider>
  );
};
