import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { useCallback } from 'react';
import createLogger from 'debug';
import { DateInputProps } from './types';
import { DateInputWithPicker } from './DateInputWithPicker';
import { useTranslation } from 'react-i18next';

const log = createLogger('components:DateInputField');

export type DateFieldProps = Omit<
  DateInputProps,
  | 'onChange'
  | 'onBlur'
  | 'value'
  | 'children'
  | 'isError'
  | 'onPickerValueChanged'
  | 'label'
> & {
  /**
   * Название поля формы
   */
  fieldName: string;
  /**
   * Ключ локализации заголовка
   */
  labelKey?: string;
};

/**
 * Поле формы для ввода даты
 *
 * @param props
 * @constructor
 */
export const DateField = (props: DateFieldProps) => {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext();
  const { fieldName, labelKey = 'datePicker.date', ...rest } = props;

  const label = t(labelKey);

  const handlePickerValueChanged = useCallback(() => {
    trigger(fieldName, { shouldFocus: true }).catch((e) =>
      log('trigger error: %O', e)
    );
  }, [fieldName, trigger]);

  const handleClear = () => {
    trigger(fieldName).then(() => {
      props.onClear?.();
    });
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field, fieldState }) => {
        log("%s: '%s' (%s)", field.name, field.value, typeof field.value);
        return (
          <DateInputWithPicker
            {...rest}
            label={label}
            value={field.value}
            onChange={field.onChange}
            isError={!!fieldState.error?.message}
            onBlur={field.onBlur}
            onPickerValueChanged={handlePickerValueChanged}
            onClear={handleClear}
          >
            {fieldState.error && (
              <FormHelperText
                color="red.500"
                mt={1}
                textStyle={TextStyles.Caption12Regular}
                px={1.5}
              >
                {fieldState.error.message}
              </FormHelperText>
            )}
          </DateInputWithPicker>
        );
      }}
    />
  );
};

export default DateField;
