import { BouncingDotsLoader } from './bouncing-dots-loader';
import React from 'react';

export const FullPageLoader = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <BouncingDotsLoader />
    </div>
  );
};
