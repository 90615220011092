import React from 'react';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import createLogger from 'debug';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import {
  FullPageLoader,
  GlobalTimerContextProvider,
  LayoutContextProvider,
  NotFound,
} from '@payler/payment-page-ui-shared';
import { PaymentScreen } from '../screens/payments/payment-screen';
import { PaymentForm, PayoutForm } from './lazy-components';
import {
  PaymentPageConfigContextProvider,
  TPaymentPageEnv,
} from '../context/config-context';
import { NetworkErrorContextProvider } from '../context/network-error-context';
import { ErrorBoundary } from './wrappers/error-boundary';
import { ChakraWrapper } from './wrappers/chakra-wrapper';
import { PayoutScreen } from '../screens/payouts/payout-screen';
import { FormVariantContextProvider } from '../context/form-variant-context';

dayjs.extend(CustomParseFormat);

const log = createLogger('app');

const queryClient = new QueryClient({
  logger: {
    log: (...args) => log('log', ...args),
    warn: (...args) => log('warn', ...args),
    error: (...args) => log('error', ...args),
  },
});

export const PaymentApp: FCC<{ env: TPaymentPageEnv }> = ({ env }) => (
  <GlobalTimerContextProvider>
    <FormVariantContextProvider>
      <ChakraWrapper>
        <HelmetProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <NetworkErrorContextProvider>
                <QueryClientProvider client={queryClient}>
                  <PaymentPageConfigContextProvider env={env}>
                    <LayoutContextProvider>
                      <React.Suspense fallback={<FullPageLoader />}>
                        <Routes>
                          <Route
                            path="payment-page"
                            element={<PaymentScreen />}
                          >
                            <Route path=":id" element={<PaymentForm />} />
                          </Route>
                          <Route path="payout-page" element={<PayoutScreen />}>
                            <Route path=":id" element={<PayoutForm />} />
                          </Route>
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                      </React.Suspense>
                    </LayoutContextProvider>
                  </PaymentPageConfigContextProvider>
                </QueryClientProvider>
              </NetworkErrorContextProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </HelmetProvider>
      </ChakraWrapper>
    </FormVariantContextProvider>
  </GlobalTimerContextProvider>
);

export default PaymentApp;
