import { usePaymentSessionState } from './payment/session';
import { useCallback } from 'react';
import {
  TRedirectDto,
  TRedirectParamsDto,
  TRedirectScripts,
} from '@payler/payment-page-api-gate';
import { isEmpty } from 'lodash';
import { assert } from '@payler/utils';
import { usePayoutMerchantUrl } from './payout';
import { useDoRedirectFlag } from '../context/config-context';

/** Переход по ссылке из redirect */
export const usePaymentRedirect = () => {
  const { session } = usePaymentSessionState();
  const replaceLocationRedirect = useReplaceLocationRedirectCallback();
  return useCallback(() => {
    const redirectUrl = session?.payment?.action?.redirect;
    if (redirectUrl) {
      handleRedirect(redirectUrl, replaceLocationRedirect);
    }
  }, [replaceLocationRedirect, session?.payment?.action?.redirect]);
};

export const usePayoutRedirect = () => {
  const redirectUrl = usePayoutMerchantUrl();
  const replaceLocationRedirect = useReplaceLocationRedirectCallback();
  return useCallback(() => {
    redirectUrl && replaceLocationRedirect(redirectUrl);
  }, [redirectUrl, replaceLocationRedirect]);
};

const useReplaceLocationRedirectCallback = () => {
  const doRedirect = useDoRedirectFlag();
  return useCallback(
    (url: string) => {
      // явно проверяем на тип
      assert(typeof url === 'string', 'URL must be string');
      doRedirect && window.location.replace(url);
    },
    [doRedirect]
  );
};

const removeScripts = () => {
  // убираем скрипты из DOM
  const scriptElements = document.querySelectorAll('script');
  scriptElements.forEach((scriptElement) => {
    scriptElement.remove();
  });
};

const asyncLoadScript = (src: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    let resolveStatus = false;
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.onerror = (err) => {
      reject(err);
    };
    script.onload = script.onratechange = () => {
      if (!resolveStatus) {
        resolveStatus = true;
        resolve();
      }
    };
    const scriptElement = document.getElementsByTagName('script')[0];
    if (scriptElement) {
      scriptElement.parentElement?.insertBefore(script, scriptElement);
    }
  });
};
const handleRedirect = (
  { id, url, httpMethod, params, scripts, submit }: TRedirectDto,
  replaceLocationRedirect: (url: string) => void
) => {
  if (!url) {
    throw new Error('No url for redirect');
  }

  if (!httpMethod) {
    replaceLocationRedirect(url);
    return;
  }
  /**TODO: Подумать над целесообразностью этого условия. Возможно стоит пересмотреть работу редиректов*/
  if (httpMethod === 'GET' && isEmpty(params) && isEmpty(scripts)) {
    replaceLocationRedirect(url);
    return;
  }

  const form = document.createElement('form');
  form.setAttribute('id', id ?? 'self-form');
  form.setAttribute('target', '_self');
  form.setAttribute('action', url);
  form.setAttribute('method', httpMethod);
  params?.forEach(({ name, value }: TRedirectParamsDto) => {
    if (!value) {
      return;
    }
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    form.appendChild(input);
  });
  document.body.appendChild(form);

  const plainScripts: HTMLScriptElement[] = [];
  const srcPromiseScripts: Promise<void>[] = [];

  scripts?.forEach(({ type, value }: TRedirectScripts) => {
    if (!value) {
      return;
    }
    if (type === 'plain') {
      const script = document.createElement('script');
      script.innerHTML = value;
      plainScripts.push(script);
    }

    if (type === 'source') {
      const promise = asyncLoadScript(value);
      srcPromiseScripts.push(promise);
    }
  });

  Promise.all(srcPromiseScripts)
    .then(() => {
      plainScripts.forEach((script) => {
        document.body.appendChild(script);
      });
      if (submit.auto) {
        form.submit();
      }
    })
    .catch(() => {
      removeScripts();
      throw new Error("Scripts didn't load");
    });
};
