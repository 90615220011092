import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import createLogger from 'debug';
import queryString from 'query-string';
import { TSessionResponse } from './session';
import {
  TConfirmPaymentParams,
  TCreateApmPaymentParams,
  TCreateCardPaymentParams,
} from './payment';
import { TDeleteSavedCardParams } from './card';
import { PaymentPageNetworkError } from './payment-page-network-error';
import {
  TCardPayoutDetails,
  TCreateApmPayoutParams,
  TPayoutResponse,
} from './payout';
import { TFacadeErrorObject, TInvalidField, TInvalidParamsDto } from './errors';

export type TNetworkErrorInfo = {
  /** Способ отображения/обработки ошибки */
  errorDisplayType: 'fullPage' | 'inForm' | 'failedScreen';
  // Имя/код/тип ошибки
  errorName?: string;
  // Сообщение в ошибке
  errorMessage?: string;
  // Полезная нагрузка в ошибке
  errorPayload?: TSessionResponse;
  // Поля с ошибками валидации
  errorInvalidFields?: TInvalidField[];
};
type TErrorHandler = (error: TNetworkErrorInfo | undefined | null) => void;

const log = createLogger('api');

const paramsSerializer = (data: object): string => {
  return queryString.stringify(data, { arrayFormat: 'none' });
};

const failedScreenErrorTypes = [
  'cardInactive',
  'operationNotSupported',
  'pinError',
  'restrictedCard',
  'cardNotFound',
  'notInvolvedIn3DS',
  'notInvolvedin3DS2',
  'unsupportedCardType',
  'balanceExceeded',
  'issuerDeclinedOperation',
  'limitExceeded',
  'afDeclined',
  'declined',
  'ppTriesNumberIsExceeded',
  'processingError',
  'paidEarlier',
  'notLastPayment',
  'transactionIsNotTwoStep',
];

export class Api {
  static getPreparedError(error: unknown): PaymentPageNetworkError | undefined {
    if (!error) {
      return undefined;
    }

    /**
     * Обернут в try/catch так, как потенциально сервер может вернуть любую ошибку
     * и при ее обработке может возникнуть ошибка рантайма
     */
    try {
      if (error instanceof AxiosError && error?.response?.data) {
        const axiosResponse: AxiosResponse<TSessionResponse | TPayoutResponse> =
          error.response;
        const paymentError: TFacadeErrorObject | undefined =
          'session' in axiosResponse.data
            ? axiosResponse.data?.session?.error
            : undefined;

        const payoutError =
          'error' in axiosResponse.data ? axiosResponse.data?.error : undefined;

        const facadeError = paymentError || payoutError;

        if (facadeError) {
          const errorData: TNetworkErrorInfo = {
            errorName: facadeError.type,
            errorMessage: facadeError.title,
            errorPayload: axiosResponse.data as TSessionResponse,
            errorInvalidFields: undefined,
            errorDisplayType: 'fullPage',
          };

          if (failedScreenErrorTypes.includes(facadeError.type)) {
            errorData.errorDisplayType = 'failedScreen';
          } else if (facadeError.type === 'validationError') {
            const invalidFields = facadeError?.['invalid-params']?.map(
              ({ name, reason }: TInvalidParamsDto) => ({
                type: 'server',
                name: name.split('.').pop(),
                message: reason,
              }),
            );

            errorData.errorDisplayType = 'inForm';
            errorData.errorInvalidFields = invalidFields;
          }

          return new PaymentPageNetworkError(errorData);
        }
      }
    } catch (e) {
      /**
       * Мы не знаем что-за ошибка пришла, поэтому стоит предположить, что ее нужно показать
       * во весь экран
       */
      return new PaymentPageNetworkError({
        errorDisplayType: 'fullPage',
        errorName: 'Unknown error',
      });
    }
  }

  private readonly axios: AxiosInstance;

  constructor(
    private baseURL: string,
    errorHandler: TErrorHandler,
  ) {
    this.axios = axios.create({
      baseURL,
      paramsSerializer,
    });
    this.axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const preparedErrorInfo = Api.getPreparedError(error);

        errorHandler(preparedErrorInfo);
        return Promise.reject(preparedErrorInfo);
      },
    );
  }

  async getSessionState(sessionId: string): Promise<TSessionResponse> {
    const resp = await this.axios.get<TSessionResponse>(`/${sessionId}/state`);
    log('getSessionState success %o', resp?.data?.session);
    return resp.data;
  }

  async createCardPayment(
    params: TCreateCardPaymentParams,
  ): Promise<TSessionResponse> {
    const resp = await this.axios.post('/payment', {
      ...params,
    });
    log('createPayment success');
    return resp.data;
  }

  async createApmPayment(
    params: TCreateApmPaymentParams,
  ): Promise<TSessionResponse> {
    const resp = await this.axios.post('/apm/payment', {
      ...params,
    });
    log('createApmPayment success');
    return resp.data;
  }

  async confirmPayment(
    sessionId: string | undefined,
    params: TConfirmPaymentParams,
  ): Promise<TSessionResponse> {
    const { attemptId, session } = params;
    const resp = await this.axios.post(
      `/${sessionId}/payment/confirm`,
      { session },
      { params: { 'attempt-id': attemptId } },
    );
    log('confirmPayment success');
    return resp.data;
  }

  async manualConfirmApmPayment(
    sessionId: string | undefined,
  ): Promise<TSessionResponse> {
    log('manualConfirmPayment success');
    const resp = await this.axios.post(
      `/${sessionId}/apm/payment/manual-confirm`,
    );

    return resp.data;
  }

  async manualRejectPayment(
    sessionId: string | undefined,
  ): Promise<TSessionResponse> {
    const resp = await this.axios.post(`/${sessionId}/payment/manual-reject`);

    log('manualReject success');

    return resp.data;
  }

  // TODO Реализовать когда будет добавлена работа с картами
  async deleteSavedCard(params: TDeleteSavedCardParams): Promise<boolean> {
    log('deleteSavedCard success');
    return false;
  }

  async createApmPayout(
    sessionId: string,
    params: TCreateApmPayoutParams,
  ): Promise<TPayoutResponse> {
    const response = await this.axios.post<TPayoutResponse>(
      `/payout/v1/sessions/${sessionId}/apm/attempts`,
      {
        ...params,
      },
    );
    return response.data;
  }

  async getPayout(sessionId: string): Promise<TPayoutResponse> {
    const response = await this.axios.get<TPayoutResponse>(
      `/payout/v1/sessions/${sessionId}`,
    );
    return response.data;
  }

  async createCardPayout(sessionId: string, params: TCardPayoutDetails) {
    const response = await this.axios.post<TPayoutResponse>(
      `/payout/v1/sessions/${sessionId}/cards/attempts`,
      {
        details: {
          type: 'card',
          ...params,
        },
      },
    );
    return response.data;
  }
}
