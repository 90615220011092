import { FC, ReactNode } from 'react';
import { Button, Card, ChakraProps, Link, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { DividedFooter } from './divided-footer';
import { BackArrowIcon } from '@payler/ui-icons';

type TDesktopWrapperProps = {
  footer?: ReactNode;
};
type TMobileWrapperProps = {
  backButtonProps?: {
    onClick: () => void;
    text: string;
  };
  footer?: ReactNode;
};
export const DesktopWrapper: FCC<TDesktopWrapperProps> = ({
  children,
  footer,
}) => {
  return (
    <VStack spacing="24px">
      <Card
        mr="auto"
        ml="auto"
        mt={104}
        w={560}
        maxW={560}
        shadow="card"
        overflow="hidden"
      >
        {children}
      </Card>
      {footer && (
        <VStack mr="auto" ml="auto" w={560} maxW={560} spacing="16px">
          {footer}
        </VStack>
      )}
    </VStack>
  );
};
export const MobileWrapper: FCC<TMobileWrapperProps> = ({
  children,
  backButtonProps,
  footer,
}) => {
  return (
    <VStack spacing={2} minH="100vh">
      {backButtonProps && (
        <VStack w="full" h={5} alignItems="flex-start">
          <Button
            onClick={backButtonProps?.onClick}
            type="button"
            variant="outline"
            border="none"
            leftIcon={<BackArrowIcon color="primary.350" />}
            color="primary.350"
            px={2}
            py={0}
          >
            {backButtonProps?.text}
          </Button>
        </VStack>
      )}
      <Card w="full" flex="auto" shadow="none">
        {children}
      </Card>
      {footer}
    </VStack>
  );
};
//region common components
export const BackToStoreLink: FC<{ url: string; text: string }> = ({
  url,
  text,
}) => {
  return (
    <Link href={url} textStyle={TextStyles.BodyUI16Medium} color="primary.350">
      {text}
    </Link>
  );
};
export const MobileFooterWrapper: FCC<{ isSticky?: boolean } & ChakraProps> = ({
  children,
  isSticky,
  ...rest
}) => (
  <VStack
    position={isSticky ? 'sticky' : undefined}
    bottom={isSticky ? 0 : undefined}
    w="full"
    spacing={2}
    {...rest}
  >
    {children}
  </VStack>
);
export const StickyDividedMobileFooter: FCC<ChakraProps> = ({
  children,
  ...rest
}) => {
  return (
    <MobileFooterWrapper isSticky bg="secondary.500" pb={2} {...rest}>
      <DividedFooter>{children}</DividedFooter>
    </MobileFooterWrapper>
  );
};
