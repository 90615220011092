import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack } from '@chakra-ui/react';
import { useLayout } from '../context/layout-context';
import { StickyDividedMobileFooter } from './wrappers';

type TSubmitFormWithBackButtonProps = {
  submitButtonText: ReactNode;
  isDisabled?: boolean;
  onBack?(): void;
  isLoading?: boolean;
};

export const SubmitFormWithBackButton: FC<TSubmitFormWithBackButtonProps> = ({
  submitButtonText,
  onBack,
  isDisabled,
  isLoading,
}) => {
  const { isMobile } = useLayout();

  if (isMobile) {
    return (
      <MobileFooter
        submitButtonText={submitButtonText}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    );
  }

  return (
    <DesktopFooter
      onBack={onBack}
      submitButtonText={submitButtonText}
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );
};

const DesktopFooter: FC<TSubmitFormWithBackButtonProps> = ({
  submitButtonText,
  onBack,
  isDisabled,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Box w="full" px={4} pb={3}>
      <HStack w="full" h="full" spacing={2}>
        {onBack && (
          <Button onClick={onBack} type="button" w="100%" variant="secondary">
            {t('buttons:back')}
          </Button>
        )}
        <Button
          type="submit"
          w="full"
          variant="primary"
          isDisabled={isDisabled}
          isLoading={isLoading}
        >
          {submitButtonText}
        </Button>
      </HStack>
    </Box>
  );
};

const MobileFooter: FC<TSubmitFormWithBackButtonProps> = ({
  submitButtonText,
  isDisabled,
  isLoading,
}) => {
  return (
    <StickyDividedMobileFooter>
      <Button
        type="submit"
        w="full"
        variant="primary"
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        {submitButtonText}
      </Button>
    </StickyDividedMobileFooter>
  );
};
