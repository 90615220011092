import { useEffect, useState } from 'react';

export const useCountdown = (seconds: number, onEnd: VoidFunction) => {
  const [remaining, setRemaining] = useState(seconds);

  useEffect(() => {
    const tick = () => {
      setRemaining(remaining - 1);
    };

    const countdown = setInterval(tick, 1000);

    if (remaining === 0) {
      clearInterval(countdown);
      onEnd();
    }

    return () => clearInterval(countdown);
  }, [onEnd, remaining]);

  return remaining;
};
