import { FC } from 'react';
import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { BouncingDotsLoader } from './bouncing-dots-loader';
import { TextStyles } from '@payler/ui-theme';

type TOperationProcessingProps = {
  title: string;
  subtitle?: string;
  message?: string;
  dotColor?: string;
};

export const OperationProcessing: FC<TOperationProcessingProps> = ({
  title,
  subtitle,
  message,
  dotColor,
}) => {
  return (
    <Box w={'100dvw'} h={'100dvh'}>
      <VStack w={'full'} h={'full'} alignItems={'center'}>
        <Center w={'full'} h={'full'} flexDirection={'column'}>
          <BouncingDotsLoader color={dotColor} />
          <Text as="h1" textStyle={TextStyles.h1} mt={1} textAlign="center">
            {title}
          </Text>
          {subtitle && (
            <Text
              textStyle={TextStyles.BodyText16Medium}
              color="primary.350"
              textAlign="center"
              mt={1}
            >
              {subtitle}
            </Text>
          )}
          {message && (
            <Text
              textStyle={TextStyles.BodyText16Medium}
              color="primary.350"
              textAlign="center"
              mt={1}
            >
              {message}
            </Text>
          )}
        </Center>
      </VStack>
    </Box>
  );
};
