import { forwardRef } from 'react';
import {
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  InputRightElementProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CopyComponent } from './copy-component';

export const InputClipboard = forwardRef<
  HTMLInputElement,
  InputProps & {
    label?: string;
    value: string;
    canCopy?: boolean;
    copyIconProps?: InputRightElementProps;
    labelProps?: TextProps;
  }
>(
  (
    { label, value, canCopy = true, copyIconProps, labelProps, ...rest },
    ref,
  ) => {
    return (
      <InputGroup>
        <Input
          minH={82}
          ref={ref}
          fontSize={18}
          fontWeight={500}
          borderRadius={0}
          textStyle={TextStyles.BodyText16Medium}
          variant="floating"
          value={value}
          readOnly
          {...rest}
        />
        {label && (
          <FormLabel variant="floating">
            <Text
              textStyle={TextStyles.Subtitle14Regular}
              color="primary.350"
              {...labelProps}
            >
              {label}
            </Text>
          </FormLabel>
        )}
        {canCopy && (
          <InputRightElement h="full" {...copyIconProps}>
            <CopyComponent value={value} />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);
