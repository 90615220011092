import { TActionDto } from './action';
import { TBrowser } from './browser';
import { TPageDto } from './page';

export type TCompletionIndicator = 'Y' | 'N' | 'U';

export type TState =
  | 'processingFlowInProgress'
  | 'pending'
  | 'tryAnotherPayment'
  | 'ppTriesNumberIsExceeded'
  | 'noPaymentsFound'
  | 'authorized'
  | 'preAuthorized'
  | 'redirect'
  | 'error';

export const PAYMENT_METHODS = [
  'cards',
  'upi',
  'netbanking',
  'bankdeposit',
  'banktransfer',
  'p2p',
  'qrcode',
  'wallet',
  'lbt',
  'poli',
  'payid',
  'payster',
  'interac',
  'hayhay',
  'pep',
  'paycell',
  'paybol',
  'banktransferp2p',
  'paparapool',
  'papara',
  'payfix',
  'communitybank',
  'instantqr',
  'onlinebankingbtv',
  'openbanking',
  'banktransferpb',
  'p2pgejpy',
  'pcr',
  'p2pgecny',
  'unionpaygecny',
  'rapipagoprars',
  'khipuprars',
  'payidtpaud',
  'p2pphonepcrrub',
  'p2pphonepcrazn',
  'p2pcardpcr',
  'p2pibanpcr',
  'virtaccmpsakrw',
  'paysterfmaud',
  'paysterhcaud',
  'openbankingseeur',
  'cryptotopupcp',
  'sepatfeur',
  'sepainstanttfeur',
  'sepaqrtfeur',
  'zenpaytfeur',
  'idealtfeur',
  'bancontacttfeur',
  'bliktfpln',
] as const;

export const PAYSTER_FLOW_METHODS: TAllMethods[] = [
  'payster',
  'paysterhcaud',
  'paysterfmaud',
] as const;

export type TAllMethods = (typeof PAYMENT_METHODS)[number];
export type TApmMethods = Exclude<TAllMethods, 'cards'>;

export type TPaymentDto = {
  method?: TAllMethods | null;
  state?: TState;
  action?: TActionDto | null;
  page?: TPageDto;
  attemptId?: string | null;
  createPaymentToken?: boolean | null;
};

export type TApmDto = {
  actionURL: string;
  httpMethod: string;
};

export type TCreateCardPaymentParams = {
  session: {
    id: string;
    payment: {
      method: {
        card: TCardMethodParam;
      };
    };
    browser: TBrowser;
  };
};

export type TCreateApmPaymentParams = {
  session: {
    id: string;
    payment: {
      method: TAllMethods;
    } & Record<string, string | number>;
    browser: TBrowser;
  };
};

export type TCardMethodParam = {
  id?: string | null;
  type?: string;
  number?: string;
  last4: string;
  codeVerification: string;
  expMonth?: number | null;
  expYear?: number | null;
  holderName?: string;
  //contactDetails?: TContactDetailsParams;
  checkboxes?: {
    toBeSaved?: boolean;
    agreement?: boolean;
    agreedToProtect?: boolean;
  };
};

export type TContactDetailsParams = {
  fullName?: {
    firstName?: string;
    surname?: string;
    patronymic?: string;
  };
  email?: string;
  phone?: string;
  address?: {
    city: string;
    country: string;
    line1?: string;
    line2?: string;
    state?: string;
    zip?: string;
    full?: string;
  };
};

export type TConfirmPaymentParams = {
  attemptId: string;
  session: {
    payment: {
      threeDSMethod: {
        attempt: {
          '3DSServerTransID': string;
          compInd: TCompletionIndicator;
        };
      };
    };
  };
};
